
import React from "react"
import Layout from "../components/layout"
import styled from "@emotion/styled"

const NameHeader = styled.h1`
  
`

const Container = styled.div`
  text-align: center;
  margin-top: 5px;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const OuterContainer = styled.div`
  display: flex;
  align-items: left;
  justify-content: space-around;
  flex-direction: row;
  padding-bottom: 1px;
`

const WorkPage = () => (
  <Layout>
    <OuterContainer>
        <Container style = {{flex: "5"}}>
                <p className="landingDescription descriptionFont">
                I am a Product Manager and a Full-Stack Developer at an early stage B2B start-up called FinBox. We provide a data platform that enables people to connect their data with financial institutions. Millions of people use FinBox products to access financial services they otherwise wouldn’t be able to access.
                </p> 
    </Container>

      </OuterContainer>

  </Layout>
)

export default WorkPage